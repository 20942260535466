import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import PageWrapper from "../components/PageWrapper";
import { Section, Title, Text } from "../components/Core";
import CTA from "../sections/nightshift/CTA";
import PostDetails from "../sections/blog/PostDetails";

import heroImg from  "../assets/nightshift/blog-kubernetes-audit-events.jpeg";

const BlogRegular = () => {
  return (
    <>
      <PageWrapper>
        <Section className="pb-0">
          <div className="pt-5"></div>
          <Container>
            <Row className="justify-content-center text-left">
              <Col lg="10">
                <Title variant="hero" className="text-center">Let's talk about MultiCloud, a Q&A for the nontechnical and busy.</Title>
                <PostDetails 
                    heroImg={heroImg}
                    imgAlt="Let's talk about MultiCloud, a Q&A for the nontechnical and busy."
                >
                    <h3>I keep hearing 'multicloud', what is it?</h3>
                    <p>A multi-cloud strategy desribes the idea that a digital service is spread across a number of cloud providers to increase redundancy in the event of outages at any single provider.</p>
                    <h3>Can I put kubernetes master nodes in multiple public clouds for redundancy?</h3>
                    <p>We don't recommend it, for the following reasons: The technology that holds all state together is called `etcd`, and etcd can tolerate (by default) about ~2ms of latency before things can be problematic and you start to trade how things are designed to work for some less desireable traits. When you include the processing overhead between devices and data centers, you can realistically put things up to about 10Km away over a fast medium (like fiber optics). While it's not impossible, there are a lot of scenerios where the default elasticity of a cloud provider goes against the idea of a geographical constraint, and we generally feel like there are much more compelling arhcitectures.</p>
                    <h3>Can I have one control plane for many clusters, across multiple public clouds?</h3>
                    <p>Yes absolutely, and the technology that makes this possible is generally referred to as a 'Service Mesh', a company called Istio created this amazing technology in 2018, now there are several means to consume similar value, for example, Google Anthos or Azure Arc have service mesh components under the hood that help make this work the way it works, and you can subscribe pretty much any k8s solution to the control plane. The target clusters call back to a centralized configuration repository, usually using git, and take configuration instructions. There are also tools like ArgoCD that are very good at mirroring k8s desired state to the live configuration we recommend.</p>
                    <h3>Can I optimize traffic geographically to increase speed and performance?</h3>
                    <p>You bet! A Global Traffic Management strategy can route a user specifically to the nearest replica of a service, and most commonly this is just another kubernetes cluster. Because applications can support multiple incoming routes, it's possible to configure a 'global' route and a 'local' route, where the local route exists to identify and debug a specific cluster, and all TLS/PKI (security stuff) and routing happens in the client resolver and 'just works'. And added benefit to standardization at this stage is a single toolset can be created and used to manage all clusters within the system.</p>
                </PostDetails>
              </Col>
            </Row>
          </Container>
        </Section>
        <CTA />
      </PageWrapper>
    </>
  );
};
export default BlogRegular;
